import React from 'react';
import Navigation from './navigation';

import ytLogo from './static/i/youtube_sm.png';
import twLogo from './static/i/twitch_sm.png';
import mxLogo from './static/i/mixcloud_sm.png';
import scLogo from './static/i/soundcloud_sm.png';

import pic1 from './static/pics/IMG_5305.JPG';
import pic2 from './static/pics/IMG_5481.JPG';
import pic3 from './static/pics/IMG_5163.JPG';
import pic4 from './static/pics/IMG_000X1.jpg';

export default function Bio() {

  const mixclouds = [
    {
      title: "UK Garage",
      date: "10/02/2022",
      url: "https://www.mixcloud.com/widget/iframe/?hide_cover=1&feed=%2Fdj_boogiebrown%2Fukg%2F"
    },
    {
      title: "UK Fresh (Live Broadcast)",
      date: "2/19/2022",
      url: "https://www.mixcloud.com/widget/iframe/?hide_cover=1&feed=%2Fdj_boogiebrown%2Fdj-boogie-brown-live-20220104-205256%2F"
    },
    {
      title: "Acapellas vs Instrumentals",
      date: "10/23/2021",
      url: "https://www.mixcloud.com/widget/iframe/?hide_cover=1&feed=%2Fdj_boogiebrown%2Fdj-boogie-brown-live-20211023-210031%2F"
    },
    {
      title: "Summer of Love 1 and 2 (Live Broadcast)",
      date: "7/3/2022",
      url: "https://www.mixcloud.com/widget/iframe/?hide_cover=1&feed=%2Fdj_boogiebrown%2Fdj-boogie-brown-live-20210703-205826%2F"
    },
    {
      title: "Freestyle #3",
      date: "6/5/2021",
      url: "https://www.mixcloud.com/widget/iframe/?hide_cover=1&feed=%2Fdj_boogiebrown%2Ffreestyle-3-mic-free%2F"
    },
    {
      title: "Freestyle #2",
      date: "5/2/2021",
      url: "https://www.mixcloud.com/widget/iframe/?hide_cover=1&feed=%2Fdj_boogiebrown%2Ffreestyle-2%2F"
    },
    {
      title: "R&B/Hip-Hop - Vol 2",
      date: "4/24/2021",
      url: "https://www.mixcloud.com/widget/iframe/?hide_cover=1&feed=%2Fdj_boogiebrown%2Frbhiphop-vol-2-mic-free%2F"
    },
    {
      title: "R&B/Hip-Hop - Vol 1",
      date: "3/28/2021",
      url: "https://www.mixcloud.com/widget/iframe/?hide_cover=1&feed=%2Fdj_boogiebrown%2Frbhiphop-vol-1%2F"
    },
    {
      title: "B.I.G. Tribue 2021",
      date: "3/9/2021",
      url: "https://www.mixcloud.com/widget/iframe/?hide_cover=1&feed=%2Fdj_boogiebrown%2Fbig-tribute%2F"
    }
  ]

  const youtubes = [
    {
      title: "New Jack Swing",
      date: "1/24/2021",
      url: "https://www.youtube.com/embed/-iTSNB_k73w"
    }
  ]

  return <div className="page">
    <div className="page-media-img page-img"></div>
    <div className="page-text">
      <div className="menu">
        <Navigation home={true} active='media' />
      </div>
      <div className="content">
        <div className="content-page">
          <h2>FEATURED LIVE SETS</h2>
          <div className='section'>
            <div className='live-sets'>

              {mixclouds.map((ele) => {
                return <div className='stream'>
                  <label>{ele.title}</label>
                  <label>Recorded: {ele.date}</label>
                  <iframe className='player' title='audio' src={ele.url} frameborder="0" ></iframe>
                </div>
              })}

              {youtubes.map((ele) => {
                return <div className='stream'>
                  <label>{ele.title}</label>
                  <label>Recorded: {ele.date}</label>
                  <iframe className='player' title='njs' src={ele.url} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
              })}

            </div>
          </div>

          <h2>CHANNELS AND MUSIC</h2>
          <div className='section'>
            <div className='live-sets channels'>
              <div className='stream stream-nowidth'>
                <label>Live Set Archive</label>
                <a href="https://www.youtube.com/channel/UCj_PnyZqfCUrB2bB7dYWxhA"><img src={ytLogo} height={45} alt='img' /></a>
              </div>
              <div className='stream stream-nowidth'>
                <label>Community</label>
                <a href="https://www.twitch.tv/dj_boogiebrown"><img src={twLogo} height={45} alt='img' /></a>
              </div>
              <div className='stream stream-nowidth'>
                <label>Mixtapes</label>
                <a href="https://www.mixcloud.com/dj_boogiebrown/"><img src={mxLogo} height={45} alt='img' /></a>
              </div>
              <div className='stream stream-nowidth'>
                <label>Music Production</label>
                <div style={{ height: "45px" }}>
                  <a href="https://soundcloud.com/user-981486087/tracks"><img src={scLogo} height={35} alt='img' style={{ marginRight: "10px" }} /></a>
                  <a href="https://www.youtube.com/user/djboogiebrown/videos"><img src={ytLogo} height={35} alt='img' /></a>
                </div>
              </div>
            </div>
          </div>

          <h2>GALLERY</h2>
          <div className='section'>
            <div className="gallery">
              <img src={pic1} height={120} width={175} alt='img' />
              <img src={pic2} height={120} width={175} alt='img' />
              <img src={pic3} height={120} width={175} alt='img' />
              <img src={pic4} height={120} width={175} alt='img' />
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>;
}