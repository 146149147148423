import React from 'react';
import Navigation from './navigation';

import twLogo from './static/i/twitter_white.png';
import inLogo from './static/i/instagram.png';
import emLogo from './static/i/email.png';

export default function Contact() {
    const [name, setName] = React.useState('');
    const [contact, setContact] = React.useState('');
    const [info, setInfo] = React.useState('');

    const mailToAction = `mailto:dj-boogiebrown@outlook.com?subject=Enquiring%20about%20DJing&body=NAME%20'${name}' CONTACT%20'${contact}' INFO%20'${info}'`;

    return <div className="page">
        <div className="page-contact-img page-img"></div>
        <div className="page-text">
            <div className="menu">
                <Navigation home={true} active='contact' />
            </div>
            <div className="content">
                <div className="info">
                    <h2>AVAILABLE FOR</h2>
                    <p>PRIVATE EVENTS - CLUBS - CORPORATE - INTERNET</p>
                    <br />
                    <h2>SOCIAL</h2>
                    <div className='social-links'>
                        <a href='mailto:boogiebrown-dj@outlook.com'><img src={emLogo} width='24px' alt='Email Link' />boogiebrown-dj@outlook.com</a><br />
                        <a href='https://twitter.com/DJ_BoogieBrown'><img src={twLogo} width='24px' alt='Twitter Link' />@dj_boogiebrown</a><br />
                        <a href='https://www.instagram.com/dj_boogiebrown/'><img src={inLogo} width='24px' alt='Instagram Link' />@dj_boogiebrown</a><br />
                    </div>
                    <br />
                    <h2>CONTACT</h2>
                    <p>SEATTLE AREA - 425 894 1604</p>
                    <br />
                    <div className="form">
                        <div>
                            <label>NAME</label>
                            <input type="text" name="contact_name" value={name} onChange={(e) => setName(e.target.value)} />
                        </div>
                        <div>
                            <label>PHONE OR EMAIL</label>
                            <input type="text" name="contact_details" value={contact} onChange={(e) => setContact(e.target.value)} />
                        </div>
                        <div>
                            <label>EVENT INFORMATION</label>
                            <textarea name="contact_info" value={info} onChange={(e) => setInfo(e.target.value)} ></textarea>
                        </div>
                        <div>
                            <a href={mailToAction} className="btn">SEND</a>
                        </div>
                        <br />
                    </div>
                </div>
            </div>
        </div>
    </div>;
}