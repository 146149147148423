import React from 'react';
import axios from 'axios';

import logoBB from './static/i/logo_main.png';

export default function KaraokeSetup() {

    const [joinCode, setJoinCode] = React.useState(null);
    const [currentCode, setCurrentCode] = React.useState("");

    React.useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios('/api/get-karaoke');
                if (response.status !== 200) throw new Error('Failed to fetch data');
                const jsonData = await response.data
                setCurrentCode(jsonData.code);
            } catch (error) {
                console.error('Error fetching JSON:', error);
            }
        };
        fetchData();
    }, [])

    const updateCode = React.useCallback(() => {
        if (!joinCode) { return; }

        const saveData = async () => {
            try {
                await axios.post('/api/save-karaoke', { data: joinCode });
                window.location.href = `/karaoke`;
            } catch (error) {
                console.error('Error saving data');
            }
        };
        saveData();
    }, [joinCode])

    return <div className="page">
        <div className="page-center">
            <div className='redirect'>
                <img src={logoBB} alt="Main logo" width={300} />
                <br />
                <h2>Set the Karaoke Session</h2>
                <input type="text" value={joinCode || currentCode} onChange={(e) => setJoinCode(e.target.value)} />
                <br />
                <div class="btn-bar">
                    <button className="btn" onClick={() => updateCode()}>Set</button>
                    <button className="btn" onClick={() => window.location.href = '/'}>Cancel</button>
                </div>
            </div>
        </div>
    </div>;
}