import React from 'react';

import logo from './static/i/mixcloud_sm.png';
import logo2 from './static/i/logo_main.png';

export default function Live() {

    React.useEffect(() => {
        setTimeout(() => {
            window.location.href = 'https://www.mixcloud.com/live/dj_boogiebrown/';
        }, 3000)
    }, [])

    return <div className="page">
        <div className="page-center">
            <div className='redirect'>
                <img src={logo2} alt="Main logo" />
                <br /><br /><br /><br />
                <h2>TRANSFERRING YOU TO</h2>
                <img src={logo} alt="Mixcloud logo" />
            </div>
        </div>
    </div>;
}