import './index.css';
import './App.css';

import React from 'react';
import ReactDOM from 'react-dom/client';

import Bio from './bio';
import Media from './media';
import Contact from './contact';
import Setups from './setups';
import Mixes from './mixes';
import Home from './home';
import YouTube from './youtube';
import Karaoke from './karoke';
import KaraokeSetup from './karokeSetup';
import Live from './live';
import {
  BrowserRouter as Router,
  Route, Routes
} from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
    <React.StrictMode>
      <div className="app">
        <Routes>
          <Route path="/bio" element={<Bio />}></Route>
          <Route path="/media" element={<Media />}></Route>
          <Route path="/contact" element={<Contact />}></Route>
          <Route path="/setups" element={<Setups />}></Route>
          <Route path="/mixes" element={<Mixes />}></Route>
          <Route path="/youtube" element={<YouTube />}></Route>
          <Route path="/karaoke/setup" element={<KaraokeSetup />}></Route>
          <Route path="/karaoke" element={<Karaoke />}></Route>          
          <Route path="/live" element={<Live />}></Route>
          <Route path="/" element={<Home />}></Route>
        </Routes>
      </div>
    </React.StrictMode>
  </Router>
);