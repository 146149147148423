import React, { useState } from 'react';
import Navigation from './navigation';

import logo from './static/i/logo_main.png';

import ytLogo from './static/i/youtube_link.png';
import ytLogoFocus from './static/i/youtube_link_focus.png';

import twLogo from './static/i/twitch_link.png';
import twLogoFocus from './static/i/twitch_link_focus.png';

import mxLogo from './static/i/mixcloud_link.png';
import mxLogoFocus from './static/i/mixcloud_link_focus.png';

//import flyer from './static/flyers/inst.ukg.png';

export default function Home() {

    // eslint-disable-next-line
    const [images, setImages] = useState({ yt: ytLogo, tw: twLogo, mx: mxLogo });

    // eslint-disable-next-line
    function swapImage(type, show) {
        let i = null;
        if (type === "yt") { i = show ? { yt: ytLogoFocus, tw: twLogo, mx: mxLogo } : { yt: ytLogo, tw: twLogo, mx: mxLogo } }
        if (type === "tw") { i = show ? { yt: ytLogo, tw: twLogoFocus, mx: mxLogo } : { yt: ytLogo, tw: twLogo, mx: mxLogo } }
        if (type === "mx") { i = show ? { yt: ytLogo, tw: twLogo, mx: mxLogoFocus } : { yt: ytLogo, tw: twLogo, mx: mxLogo } }
        setImages(i);
    }

    return <div className="home">
        <div className="img-container"></div>
        <div className="content">
            <div className="header">
                <img src={logo} alt='logo' />
                <Navigation home={false} />
            </div>
            {/* <div className="flyer">
                <div style={{ margin: "10px" }}>Click here to go to the Live Stream</div>
                <a href="https://www.mixcloud.com/live/dj_boogiebrown/"><img className='flyer' src={flyer} alt='flyer' /></a>
            </div> */}
            <div className='footer'>
                Broadcasting Live Streams
                <div className='live-links'>
                    <a href="https://www.mixcloud.com/live/dj_boogiebrown/"><img onMouseOutCapture={() => swapImage("mx", false)} onMouseOverCapture={() => swapImage("mx", true)} src={images.mx} alt='Goto Mixcloud Channel' /></a>
                    {/* <a href="https://www.youtube.com/channel/UCj_PnyZqfCUrB2bB7dYWxhA"><img onMouseOutCapture={() => swapImage("yt", false)} onMouseOverCapture={() => swapImage("yt", true)} src={images.yt} alt='Goto YouTube Channel' /></a>
                    <a href="https://www.twitch.tv/dj_boogiebrown"><img onMouseOutCapture={() => swapImage("tw", false)} onMouseOverCapture={() => swapImage("tw", true)} src={images.tw} alt='Goto Twitch Channel' /></a> */}
                </div>
            </div>
        </div>
    </div>;
}