import React from 'react';
import Navigation from './navigation';

export default function Setups() {
    return <div className="page">
        <div className="page-media-img page-img"></div>
        <div className="page-text">
            <div className="menu">
                <Navigation home={true} />
            </div>
            <div className="content">
                <div className="content-page">

                    <h2>SETUPS</h2>
                    <div className='info'>
                        <h3>DJ SET ONLY</h3>
                        ENQUIRE ON HOURLY RATE
                    </div>
                    <div className='info'>
                        <h3>MOBILE/EVENT DJ SET UP</h3>
                        DJ BOOTH<br />
                        WIRED MIC<br />
                        TURNTABLES OR CONTROLLER<br />
                        10” 300W SPEAKERS<br />
                        12” 500w SUBWOOFERS<br />
                        (INC. DJ SET)
                    </div>
                    <div className='info'>
                        <h3>SOUND TRIGGERED LIGHTING SET UP</h3>
                        PARS<br />
                        RGBAW+U COLOR RANGE
                    </div>
                    <div className='info'>
                        <h3>DMX LIGHTING SET UP</h3>
                        PARS/LEDS/LAZER<br />
                        GOBO MOVING HEADS<br />
                        RGBAW+U COLOR RANGE
                    </div>
                    <div className='info'>
                        <h3>UPLIGHTING SET UP</h3>
                        RGBAW+U COLOR RANGE<br />
                        DMX CONTROLLED
                    </div>
                    <div className='info'>
                        <h3>SET UP/TEAR DOWN</h3>
                        ENQUIRE ON HOURLY RATE
                    </div>
                    <div className='info'>
                        <p>ALL AUDIO SETUPS COMPATIABLE WITH THE MAJORITY OF PA OR INHOUSE AUDIO SYSTEMS</p>
                    </div>
                </div>
            </div>
        </div>
    </div >;
}