import React from 'react';
import axios from 'axios';
import logo from './static/i/singingmachine.png';
import logoBB from './static/i/logo_main.png';

export default function Karaoke() {

    const [joinCode, setJoinCode] = React.useState(null);

    React.useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios('/api/get-karaoke');
                if (response.status !== 200) throw new Error('Failed to fetch data');
                const jsonData = await response.data
                setJoinCode(jsonData.code);
            } catch (error) {
                console.error('Error fetching JSON:', error);
            }
        };
        fetchData();
    }, [])

    React.useEffect(() => {
        if (!joinCode) { return; }
        setTimeout(() => {
            window.location.href = `https://karaoke-web-companion-prod.stingray.com/en/join?id=${joinCode}`;
        }, 3000);
    }, [joinCode])

    return <div className="page">
        <div className="page-center">
            <div className='redirect'>
                <img src={logoBB} alt="Main logo" width={300} />
                <br />
                <h2>Joining the current Karaoke session. Please wait...</h2>
                <br />
                <img src={logo} alt="Singing Machine" />
            </div>
        </div>
    </div>;
}