import React from 'react';
import Navigation from './navigation';
import { ReactSVG } from 'react-svg'
import dlSvg from './static/svg/dl.svg';

export default function Setups() {
    return <div className="page">
        <div className="page-mixes-img page-img"></div>
        <div className="page-text">
            <div className="menu">
                <Navigation home={true} />
            </div>
            <div className="content">
                <div className="content-page">
                    <h2>"Mic-Free" MIXES</h2>
                    <p>Provided for promotional use only</p>
                    <div className='section-table'>
                        <table width="100%" cellPadding='8'>
                            <thead>
                                <tr>
                                    <td>Recorded</td>
                                    <td><div className='td-row'>Download<ReactSVG className='svg-fill' src={dlSvg} /></div></td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Tue 9th Mar 2021</td>
                                    <td><a href="https://www.icloud.com/iclouddrive/0EEAwC5-V50Vdg_8wqyYkYUOw#2021-03-09_18-53-31-BIG-Tribute">B.I.G. Tribute</a></td>
                                </tr>
                                <tr>
                                    <td>Sat 27th Mar 2021</td>
                                    <td><a href="https://www.icloud.com/iclouddrive/0sjuWrI8dPgOsiXewVcksE0mQ#2021-03-27_18-00-00-R%26B1">R&B/Hip Hop VOL1</a></td>
                                </tr>
                                <tr>
                                    <td>Sat 4th April 2021</td>
                                    <td><a href="https://www.icloud.com/iclouddrive/0uoWxpvjoknsMa2GfoMzm7e1Q#2021-04-24_18-00-00-R%26B2">R&B/Hip Hop VOL2</a></td>
                                </tr>
                                <tr>
                                    <td>Sat 23rd May 2021</td>
                                    <td><a href="https://www.icloud.com/iclouddrive/0yhAyf5N6GLc5_6LPaCvb3Xfw#2021-05-22_18-00-00-FREESTYLE2">Freestyle #2</a></td>
                                </tr>
                                <tr>
                                    <td>Sat 6th June 2021</td>
                                    <td><a href="https://www.icloud.com/iclouddrive/0-ThGh-br6WUXagVpwgtc9nyg#2021-06-06_18-00-00-FREESTYLE3">Freestyle #3</a></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>;
}