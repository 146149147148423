import React from 'react';
import Navigation from './navigation';
import logo from './static/i/logo_stacked.png';

export default function Bio() {
  return <div className="page">
    <div className="page-bio-img page-img"></div>
    <div className="page-text">
      <div className="menu">
        <Navigation home={true} active='bio' />
      </div>
      <div className="content">
        <div className="content-page">
          <img className="page-logo" src={logo} alt='logo'></img>
          <h2>Boogie Brown: A Lifelong Journey in Music</h2>
          <span style={{ fontSize: "1.4rem" }}>
            <p>Boogie Brown’s passion for DJing began in the 1980s, shaped by a love for diverse musical styles. Influenced by New York’s hip-hop scene, Chicago’s house music, the smooth rhythms of 1970s-80s R&B, and London’s dynamic sound system culture, his sets reflect a deep appreciation for music’s ability to bring people together.</p>
            <p>Primarily a beat-mixing DJ, Boogie Brown incorporates elements of scratching into his performances, though he focuses on creating seamless, engaging sets rather than technical showmanship. His journey started with small gatherings, playing music for friends at house parties and organizing high school events. Over time, these early experiences grew into a 20-year mobile DJ business, where he developed a reputation for professionalism and reliability in private event services.</p>
            <p>As his style evolved, Boogie Brown began performing in bars and nightclubs, eventually earning a residency at a local bar and guest appearances at various venues. These opportunities helped him connect with broader audiences and further refine his craft.</p>
            <p>In the mid-2000s, he began experimenting with music production, drawing on the classic two-turntable approach to create remixes and mashups that complemented his sets. This exploration led to collaborations with local artists and a small collection of remixes, some of which are available on his media page.</p>
            <p>In 2007, Boogie Brown moved to Seattle and took a short break from performing to explore the local music scene as a listener and fan. This time away allowed him to rediscover his love for DJing, and he now performs through live streams and private events, sharing his passion for music with those who enjoy his unique blend of sounds.</p>
            <p>To hear his work or get in touch, visit the media and contact sections.</p>
          </span>
        </div>
      </div>
      <br />
    </div>
  </div>;
}