import React from 'react';
import { Link } from "react-router-dom";

export default function Navigation(props) {
  return <div className="nav-container">
    {props.home ? <><div><Link to="/">{'<'}</Link></div></> : null}
    <div><Link to="/bio" className={props.active === 'bio' ? 'nav-active' : ''}>BIO</Link></div>
    <div className='nav-sep'>-</div>
    <div><Link to="/media" className={props.active === 'media' ? 'nav-active' : ''}>MEDIA</Link></div>
    <div className='nav-sep'>-</div>
    <div><Link to="/contact" className={props.active === 'contact' ? 'nav-active' : ''}>CONTACT</Link></div>
  </div>
}