import React from 'react';

import logo from './static/i/youtube_color.png';
import logo2 from './static/i/logo_main.png';

export default function YouTube() {

    React.useEffect(() => {
        setTimeout(() => {
            window.location.href = 'https://www.youtube.com/channel/UCj_PnyZqfCUrB2bB7dYWxhA';
        }, 3000)
    }, [])

    return <div className="page">
        <div className="page-center">
            <div className='redirect'>
                <img src={logo2} alt="Main logo"/>
                <br /><br /><br /><br />
                <h2>TRANSFERRING YOU TO</h2>
                <img src={logo} alt="YouTube logo"/>
            </div>
        </div>
    </div>;
}